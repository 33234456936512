<template>
  <div>
    <base-dialog :show='!!error' title='An error ocurred!' @close='handError'>
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <base-card>
        <header>
          <h2>Requests Received</h2>
        </header>
        <base-spinner v-if='isLoading'></base-spinner>
        <ul v-else-if='!isLoading && hasRequest'>
          <request-item v-for='request in receivedRequests'
                        :key='request.id'
                        :email='request.userEmail'
                        :message='request.message'></request-item>
        </ul>
        <h3 v-else style='color: darkgrey'>You haven't received any request yet!</h3>
      </base-card>
    </section>
  </div>
</template>

<script>
import requestItem from '@/components/requests/RequestItem';

export default {
  name: 'RequestsReceived',
  components: {
    requestItem,
  },
  data() {
    return {
      isLoading: true,
      error: null,
    }
  },
  created() {
    this.loadRequests()
  },
  computed: {
    receivedRequests() {
      return this.$store.getters['requests/requests']
    },
    hasRequest() {
      return this.$store.getters['requests/hasRequest']
    }
  },
  methods: {
    async loadRequests() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('requests/fetchRequests');
      } catch (error) {
        this.error = error.message || "Something failed!";
      }
      this.isLoading = false;
    },
    handError() {
      this.error = null;
    }
  }
};
</script>

<style scoped>
header {
  text-align: center;
}

ul {
  list-style: none;
  margin: 2rem auto;
  padding: 0;
  max-width: 30rem;
}

h3 {
  text-align: center;
}
</style>
